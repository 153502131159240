import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import '../styles/custom.css';
import '../styles/layout.css';
import NavigationProvider, {NavigationContext} from '../services/navigation';
import theme, {menuTransitionDuration} from '../styles/theme';
import Menu from './Menu';
import Backgrounds from './Backgrounds';
import {devices} from '../styles/breakpoints';
import window from '../utils/window';
import Footer from './Footer';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: 100svh; /* Fallback for browsers that do not support Custom Properties */
  /* min-height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  position: relative;
`;

const ContentContainer = styled.div`
  width: 100%;
  flex: 1;
  max-width: 1440px;
  overflow-x: hidden;
  padding: 40px 0px;
  z-index: 2;
  @media ${devices.tabletPortrait} {
    padding: 64px;
  }
  @media ${devices.desktop} {
    padding: 96px;
  }
`;

export const PageContent = React.forwardRef<HTMLInputElement, any>((props, ref) => {
  return (
    <ContentContainer className={props.className} ref={ref}>
    {props.children}
  </ContentContainer>
  );
});

const PageContentContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  transition: all ${menuTransitionDuration}ms;
  position: relative;
`;

const Page: React.FC = ({children}) => {
  return (
    <PageContentContainer>
      {children}
    </PageContentContainer>
  );
};

const Overlay = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; 
  min-height: calc(var(--vh, 1vh) * 100);
  transition: all ${menuTransitionDuration}ms;
  transition-timing-function: ease-out;
`;

const OverlayC: React.FC = () => {
  const {location} = React.useContext(NavigationContext);
  return (
    <Overlay style={{
      backgroundColor: location !== '/' ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.7)'
    }}/>
  )
}
const Layout: React.FC = ({children}) => {
  const {setBackgroundHeight, location} = React.useContext(NavigationContext);
  React.useEffect(() => {
    const handleResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const ref = React.useRef();
  React.useEffect(() => {
    const pageHeight = (ref.current as any)?.clientHeight;
    setBackgroundHeight(pageHeight)
  }, [location])
  return (
    <ThemeProvider theme={theme}>
      <NavigationProvider>
        <LayoutContainer ref={ref}>
          <Page>
            <Backgrounds />
            <Menu />
            <OverlayC />
            {/* <_PageContentContainer> */}
              {children}
            {/* </_PageConstentContainer> */}
            <Footer />
          </Page>
        </LayoutContainer>
      </NavigationProvider>
    </ThemeProvider>
  );
};

export default Layout;

